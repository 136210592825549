<template>
	<div class="container d-flex justify-content-center align-items-center flex-column p-10">
		<may2022 v-if="version == '1.5.0'" />
		<latemay2022 v-if="version == '1.5.1'" />
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { mapGetters } from 'vuex';

export default {
	name: 'ReleaseNotes',
	props: ['version'],
	components: {
		may2022: () => import('@/view/content/widgets/releasenotes/1.5.0.vue'),
		latemay2022: () => import('@/view/content/widgets/releasenotes/1.5.1.vue'),
	},
	computed: {
		...mapGetters(['currentUser']),
	},
	data() {
		return {
			loaded: false,
		};
	},
	created() {
		if (this.currentUser.role !== 'admin') {
			this.$router.push({ name: 'dash' });
		}
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Release Notes', route: { name: 'releasenotesindex' } },
			{ title: '2022' },
			{ title: '05' },
			{ title: '' },
		]);
	},
};
</script>

<style scoped lang="scss">
.container {
	background: white;
}
</style>
